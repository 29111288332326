import React, {useEffect, useRef} from 'react';
import ModalProgress from "../common/ModalProgress";
import {useState} from "react";
import {PermissionUtil} from "../../utils/PermissionUtil";
import ReactSelect from "../common/ReactSelect";
import {CommonUtil} from "../../utils/CommonUtil";
import {SketchPicker} from "react-color";

import Modal from "react-responsive-modal";
import ServicesSelector from "../services/ServicesSelector";
import ChatFlowsSelector from "../chatflows/ChatFlowsSelector";
import Axios from "axios";
import {API} from "../../api/APIClient";
import {PluginSetupExplain} from "./PluginSetupExplain";
import {PluginUtil} from "../../utils/PluginUtil";
import {PluginChatFlowListBuilder} from "./PluginChatFlowListBuilder";

import {PluginSupportSelector} from "./PluginSupportSelector";
import {SectionType} from "../../constants/SectionType";
import {Toast} from "../../utils/ToastUtil";
import axios from "axios";
import {PluginFormNavigationPreview} from "./PluginFormNavigationPreview";
import FileInput from "../common/FileInput";
import {UploadType} from "../../utils/UploadType";

import iconCharley from "../../assets/img/plugin/charley_wave_full.svg";
import iconBack from "../../assets/img/plugin/ic_back.svg";

import "../../uwbp.css";
import "../../plugin-cms.css";
import {NotificationManager} from "react-notifications";
import {ContextUtil} from "../../utils/ContextUtil";

export const PluginForm = (props) => {

    const MODAL_MODE_NONE = -1;
    const MODAL_MODE_CHAT_FLOW = 1;
    const MODAL_MODE_SERVICE = 2;
    const MODAL_MODE_CHAT_FLOW_LIST = 3;
    const MODAL_MODE_SUPPORT = 5;

    const {pluginId} = props;
    const {callback} = props;

    const [plugin, setPlugin] = useState();
    const [cmsConfig, setCmsConfig] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingOrganisations, setIsLoadingOrganisations] = useState(false);
    const [isLoadingUserRoles, setIsLoadingUserRoles] = useState(false);
    const [isLoadingLocations, setIsLoadingLocations] = useState(false);

    const [isUploading, setIsUploading] = useState(false);
    const [organisationOptions, setOrganisationOptions] = useState();
    const [userRoleOptions, setUserRoleOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);

    const [widgetGraphicFile, setWidgetGraphicFile] = useState(undefined);
    const [widgetGraphicData, setWidgetGraphicData] = useState(undefined);

    const [welcomeGraphicFile, setWelcomeGraphicFile] = useState(undefined);
    const [welcomeGraphicData, setWelcomeGraphicData] = useState(undefined);

    const [formData, setFormData] = useState({});
    const [touched, setTouched] = useState({});
    const [errors, setErrors] = useState({});

    const [colourPickerShown, setColourPickerShown] = useState(-1);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState(MODAL_MODE_NONE);
    const modalMetadata = useRef({});

    useEffect(() => {
        getCMSConfigFromNetwork();

        if (pluginId) {
            fetchPluginConfigFromNetwork();
        }

        if (PermissionUtil.allOrgs("adminUser")) {
            fetchOrganisationsOverNetwork();
        }

        fetchUserRolesFromNetwork();
        if (!pluginId || !PermissionUtil.allOrgs("plugin")) {
            fetchCampusesFromNetwork();
        }
    }, []);

    useEffect(() => {
        if (plugin) {
            let organisationID = CommonUtil.getOrDefault(plugin, "organisationID");
            if (!organisationID) {
                organisationID = ContextUtil.getUser().organisationID;
            }

            const newFormData = {
                id : plugin.pluginId,
                name : plugin.name,
                organisationID
            };

            const metadata = JSON.parse(plugin.data);
            const keys = Object.keys(metadata);
            keys.forEach((key) => {
                newFormData[key] = metadata[key];
            });
            setFormData(newFormData);

            fetchUserRolesFromNetwork();
            fetchCampusesFromNetwork(organisationID);
        }
    }, [plugin]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        if (validateForm()) {
            submitPluginConfigOverNetwork();
        }
    }

    function validateForm() {
        let out = true;
        const errors = {};

        const requiredFields = [
            { "key" : "name", "label" : "Name" },
            { "key" : "chatFlowID", "label" : "Default Chat Flow" },
            // { "key" : "sosServiceID", "label" : "SOS Service" }
        ];

        for (let i = 0; i < requiredFields.length; i++) {
            let problem = false;

            const field = requiredFields[i];
            if (formData.hasOwnProperty(field.key)) {
                if (formData[field.key] === undefined || formData[field.key] === null) {
                    problem = true;
                }
            } else {
                problem = true;
            }

            if (problem) {
                out = false;
                errors[field.key] = "Please provide value for '" + field.label + "'";
            }
        }

        return out;
    }

    function handleBlur(event) {
        const name = event.target.name;
        const newTouched = CommonUtil.cloneObject(touched);
        newTouched[name] = true;
        setTouched(touched);
    }

    function handleChange(event) {
        let value = event.target.type === "checkbox" ? (event.target.checked ? 1 : 0) : event.target.value;

        if (event.target.name === PluginUtil.configKeys.WIDGET_EMBED_TYPE) {
            console.log("HANDLE CHANGE WIDGET EMBED TYPE");
            if (event.target.checked) {
                value = PluginUtil.embedTypes.FLOATING;
            } else {
                value = PluginUtil.embedTypes.STATIC;
            }
            console.log("VALUE: ", value);
        }

        let outData = {};
        outData[event.target.name] = value;
        putFormData(outData);
    }

    function handleColourChange(name, colour) {
        const value = colour.hex;

        let outData = {};
        outData[name] = value;
        putFormData(outData);
    }

    function putFormData(data) {
        if (!CommonUtil.isObject(data)) {
            return;
        }

        const newFormData = CommonUtil.cloneObject(formData);
        const keys = Object.keys(data);

        keys.forEach((key) => {
            const newData = data[key];
            if (newData !== undefined) {
                newFormData[key] = data[key];
            } else {
                delete newFormData[key];
            }
        });

        setFormData(newFormData);

        if (data.hasOwnProperty("organisationID")) {
            fetchCampusesFromNetwork(data.organisationID);
        }
    }

    function colourPickerDidCallback(action, data) {
        if (action === "focus") {
            setColourPickerShown(data);
        } else if (action === "blur") {
            setColourPickerShown(null);
        } else if (action === "change") {
            handleColourChange(data.name, data.color);
        }
    }

    function pluginModalDidDismiss() {
        setModalOpen(false);
    }

    function showPluginModal(mode, metadata) {
        modalMetadata.current = metadata;
        setModalMode(mode);
        setModalOpen(true);
    }

    function chatFlowWasRequested() {
        showPluginModal(MODAL_MODE_CHAT_FLOW, {});
    }

    function serviceWasRequested(prefix) {
        showPluginModal(MODAL_MODE_SERVICE, { prefix });
    }

    function isServiceSupported(serviceTypeID) {
        return PluginUtil.serviceTypesSupported.includes(parseInt(serviceTypeID));
    }

    function chatFlowListWasRequested() {
        setModalMode(MODAL_MODE_CHAT_FLOW_LIST);
        setModalOpen(true);
    }

    function supportItemWasRequested(typeId, key) {
        modalMetadata.current = { typeId, key };
        setModalMode(MODAL_MODE_SUPPORT);
        setModalOpen(true);
    }

    function supportTableDidCallback(action, data) {
        if (action === "click") {
            setModalOpen(false);
            setModalMode(-1);

            if (modalMetadata.current) {
                const out = {};
                out[modalMetadata.current.key + "ID"] = data.id;
                out[modalMetadata.current.key + "Title"] = data.name;

                putFormData(out);
            }
        } else if (action === "dismiss") {
            setModalOpen(false);
            setModalMode(-1);
        }
    }

    function handleFilePickerChange(e, type) {
        e.preventDefault();

        if (e.target.files.length > 0) {
            const reader = new FileReader();
            const file = e.target.files[0];

            if (file) {
                reader.onloadend = () => {
                    if (type === "widget") {
                        setWidgetGraphicFile(file);
                        setWidgetGraphicData(reader.result);
                    } else {
                        setWelcomeGraphicFile(file);
                        setWelcomeGraphicData(reader.result);
                    }
                }

                reader.readAsDataURL(file);
            }
        }
    }

    function handleFileDelete(type) {
        if (type === "widget") {
            setWidgetGraphicFile(undefined);
            setWidgetGraphicData(undefined);
        } else {
            setWelcomeGraphicFile(undefined);
            setWelcomeGraphicData(undefined);
        }
    }

    // NETWORK

    function fetchOrganisationsOverNetwork() {
        if (isLoadingOrganisations) return;
        setIsLoadingOrganisations(true);

        Axios.get(API.organisation.getOrganisations, {
            props : {
                appUsersOnly : 1
            }
        })
        .then((r) => {
            const resp = API.parse(r);
            if (resp.success) {
                const organisations = resp.data.organisations;
                if (organisations) {
                    const orgOptions = organisations.map((org) => {
                        return {
                            value : org.id.toString(),
                            label : org.name
                        }
                    });
                    setOrganisationOptions(orgOptions);
                }
            } else {
                console.log(API.formatError(resp));
            }
            setIsLoadingOrganisations(false);
        })
        .catch((e) => {
            console.log(e);
            setIsLoadingOrganisations(false);
        });
    }

    function fetchPluginConfigFromNetwork() {
        if (isLoading) return;
        setIsLoading(true);

        const data = {
            pluginConfigId : pluginId
        };

        Axios.post(API.plugin.getPluginConfig, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data) {
                        if (resp.data.pluginConfig) {
                            setPlugin(resp.data.pluginConfig);
                        }
                    }
                } else {
                    console.log(API.formatError(r));
                }
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
            });
    }

    function submitPluginConfigOverNetwork(bypassImageUpload, inData) {
        if (isLoading) return;
        setIsLoading(true);

        const data = inData ? inData : CommonUtil.cloneObject(formData);
        if (formData.id) {
            data.id = formData.id;
        } else if (plugin && plugin.pluginId) {
            data.id = formData.pluginId;
        } else if (pluginId) {
            data.pluginId = pluginId;
        }

        if (bypassImageUpload === undefined) {
            bypassImageUpload = false;
        }

        if (!bypassImageUpload) {
            // Determine if we have any images to upload
            const uploadImages = [];
            if (widgetGraphicFile) {
                uploadImages.push({
                    type: UploadType.PLUGIN_ASSISTANT_WIDGET_IMAGE,
                    file: widgetGraphicFile,
                    dataKeyID : PluginUtil.configKeys.WIDGET_ASSISTANT_IMAGE_ID,
                    dataKeyUrl : PluginUtil.configKeys.WIDGET_ASSISTANT_IMAGE_URL
                });
            }

            if (welcomeGraphicFile) {
                uploadImages.push({
                    type: UploadType.PLUGIN_ASSISTANT_WELCOME_IMAGE,
                    file: welcomeGraphicFile,
                    dataKeyID : PluginUtil.configKeys.WELCOME_SCREEN_ASSISTANT_IMAGE_ID,
                    dataKeyUrl : PluginUtil.configKeys.WELCOME_SCREEN_ASSISTANT_IMAGE_URL
                });
            }

            if (uploadImages.length > 0) {
                let uploadIndex = 0;

                const uploadCallback = (success, inData) => {
                    uploadIndex++;
                    if (uploadIndex >= uploadImages.length) {
                        // Images are uploaded, remove file references
                        setWidgetGraphicFile(undefined);
                        setWelcomeGraphicFile(undefined);

                        // And now call the submit function but with the bypass flag set
                        submitPluginConfigOverNetwork(true, inData);
                        return;
                    }

                    uploadImageOverNetwork(uploadImages[uploadIndex], uploadCallback, inData);
                };

                uploadImageOverNetwork(uploadImages[0], uploadCallback, data);
                return;
            }
        }

        Axios.post(API.plugin.submitPlugin, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setPlugin(resp.data.pluginConfig);
                    onSubmissionCompleted();
                } else {
                    console.log(API.formatError(r));

                    NotificationManager.error(API.formatError(r));

                    setIsLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
            });
    }

    function uploadImageOverNetwork(data, callback, pluginData) {
        setIsLoading(true);

        console.log("Upload image: ", data);

        const formData = new FormData();
        formData.append("typeID", data.type);
        formData.append("file", data.file);

        Axios.post(API.image.uploadImage, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    pluginData[data.dataKeyID] = resp.data.imageID;
                    pluginData[data.dataKeyUrl] = resp.data.imageURL;

                    callback(true, pluginData);
                } else {
                    console.log(API.formatError(resp));
                    callback(false);
                }
            })
            .catch((e) => {
                console.log(e);
                callback(false);
            });
    }

    function onSubmissionCompleted() {
        setIsLoading(false);

        NotificationManager.success("Plugin Configuration has been saved");
    }

    function fetchUserRolesFromNetwork() {
        if (isLoadingUserRoles) return;
        setIsLoadingUserRoles(true);

        Axios.get(API.appUser.getRoleOptions)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    const options = resp.data.roleOptions.map((role) => {
                        return {
                            value: role.id.toString(),
                            label: role.name
                        };
                    })
                    setUserRoleOptions(options);
                } else {
                    console.log(API.formatError(resp));
                }

                setIsLoadingUserRoles(false);
            })
            .catch((e) => {
                console.log(e);
                setIsLoadingUserRoles(false);
            });
    }

    function fetchCampusesFromNetwork(organisationID) {
        if (!organisationID) {
            organisationID = CommonUtil.getOrDefault(formData, "organisationID");
        }

        if (!organisationID) {
            organisationID = ContextUtil.getUser().organisationID;
        }

        if (!organisationID || isLoadingLocations) return;
        setIsLoadingLocations(true);

        Axios.get(API.campus.getCampuses, {params: {organisationID}})
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    const locations = resp.data.campusOptions.map((campus) => {
                        return {
                            value: campus.id.toString(),
                            label: campus.name
                        };
                    })
                    setLocationOptions(locations);
                } else {
                    console.log(API.formatError(resp));
                }
                setIsLoadingLocations(false);
            })
            .catch((e) => {
                console.log(e);
                setIsLoadingLocations(false);
            });
    }

    function getCMSConfigFromNetwork() {
        axios.get(API.plugin.getCmsConfig)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setCmsConfig(resp.data);
                } else {
                    console.log(API.formatError(r));
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // RENDER
    let modalTitle = "New Configuration";
    if (pluginId) {
        modalTitle = "Edit Configuration";
    }

    let organisationPicker = [];
    if (PermissionUtil.allOrgs("plugin")) {
        organisationPicker = <div className="grid-x grid-padding-x">
            <div className="small-3 cell">
                <label htmlFor="organisationID" className="text-right middle">* Created By</label>
            </div>
            <div className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "organisationID", null) && "error")}>
                <ReactSelect
                    name="organisationID"
                    className="react-select"
                    onChange={handleChange}
                    value={CommonUtil.getOrDefault(formData, "organisationID", null)}
                    options={organisationOptions}
                    onBlur={handleBlur}
                />
                <small className="error">{CommonUtil.getOrDefault(errors, "organisationID", "")}</small>
            </div>
        </div>
    }

    let modalContent = [];
    if (modalMode === MODAL_MODE_CHAT_FLOW) {
        let chatFlowID = CommonUtil.getOrDefault("formData", "chatFlowID", null);
        let selectedValue = [];
        if (chatFlowID !== null) {
            selectedValue.push(chatFlowID);
        }

        modalContent = (
            <ChatFlowsSelector
                isSingleSelection
                onCancel={() => setModalOpen(false)}
                pluginCompatible={1}
                selected={selectedValue}
                onSubmitted={(chatFlow) => {
                    if (chatFlow) {
                        const outData = {
                            chatFlowID : chatFlow.id,
                            chatFlowTitle : chatFlow.title,
                            chatFlowCompatibility : chatFlow.pluginCompatible
                        };
                        putFormData(outData);
                    }
                    setModalOpen(false);
                }}
                onClose={() => {
                    setModalOpen(false);
                }}/>
        );
    } else if (modalMode === MODAL_MODE_SERVICE) {
        modalContent = (
            <ServicesSelector
                isSingleSelection
                serviceTypes={PluginUtil.serviceTypesSupported}
                onCancel={() => setModalOpen(false)}
                onSubmitted={(service) => {
                    if (modalMetadata.current && service) {
                        let keyPrefix = "";
                        if (modalMetadata.current.hasOwnProperty("prefix")) {
                            keyPrefix = modalMetadata.current.prefix;
                        }
                        const outData = {}
                        outData[keyPrefix + "ID"] = service.id;
                        outData[keyPrefix + "Title"] = service.title;
                        outData[keyPrefix + "TypeID"] = service.typeID;
                        putFormData(outData);
                    }
                    setModalOpen(false)
                }}
            />
        );
    } else if (modalMode === MODAL_MODE_CHAT_FLOW_LIST) {
        modalContent = (
            <PluginChatFlowListBuilder
                data={CommonUtil.getOrDefault(formData, "chatFlows", [])}
                callback={(action, data) => {
                    if (action === "submit") {
                        putFormData({
                            chatFlows : JSON.stringify(data)
                        });

                        setModalOpen(false);
                        setModalMode(-1);
                    } else if (action === "cancel") {
                        setModalOpen(false);
                        setModalMode(-1);
                    }
                }} />
        )
    } else if (modalMode === MODAL_MODE_SUPPORT) {
        modalContent = (
            <PluginSupportSelector
                sectionType={modalMetadata.current.typeId}
                callback={supportTableDidCallback} />
        );
    }

    let sosServiceWarningElem = [];
    // if (CommonUtil.getOrDefault(formData, "sosServiceID") && !isServiceSupported(CommonUtil.getOrDefault(formData, "sosServiceTypeID", -1))) {
    //     sosServiceWarningElem = (
    //         <div className={"alert alert-warning"}>This Service is not supported in the Plugin.</div>
    //     );
    // }

    let menuServiceWarningElem = [];
    // if (CommonUtil.getOrDefault(formData, "menuServiceID") && !isServiceSupported(CommonUtil.getOrDefault(formData, "menuServiceTypeID", -1))) {
    //     menuServiceWarningElem = (
    //         <div className={"alert alert-warning"}>This Service is not supported in the Plugin.</div>
    //     )
    // }

    let integrationExplainElem = (
        <div className="grid-x grid-padding-x" style={{marginBottom: "10px"}}>
            <div className={"small-12 cell"} style={{textAlign : "center", fontStyle : "italic"}}>
                <p>Please save this Configuration to see how to integrate the Plugin into your website.</p>
            </div>
        </div>
    );
    if (plugin) {
        integrationExplainElem = (
            <>
                <div className="grid-x grid-padding-x" style={{marginBottom: "10px"}}>
                    <div className={"small-12 cell"}>
                    <p>Add the UniWellBeing Plugin to your website. Pick how you would like to add the Plugin to your
                            site:</p>
                    </div>
                </div>

                <div className="grid-x grid-padding-x" style={{marginBottom: "10px"}}>
                    <div className={"small-12 cell"}>

                        <PluginSetupExplain
                            configId={CommonUtil.getOrDefault(plugin, "pluginId")}
                            formData={formData}
                            cmsConfig={cmsConfig}
                        />

                    </div>
                </div>
            </>
        );
    }

    let defaultChatFlowWarningElem = [];
    if (parseInt(CommonUtil.getOrDefault(formData, "chatFlowCompatibility", -1)) === 0) {
        defaultChatFlowWarningElem = (
            <div className={"alert-message alert-warning"} style={{fontSize:"small"}}>This Chat Flow has not been marked as Plugin Compatible. It may contain incompatible functionality. Please review carefully.</div>
        );
    }

    // HEADER CONFIG
    let headerConfigContent = [];
    if (CommonUtil.getOrDefault(formData, PluginUtil.configKeys.CHAT_SHOW_NAVIGATION, true)) {
        let sosOptions = [];
        if (CommonUtil.getOrDefault(formData, PluginUtil.configKeys.CHAT_SHOW_SOS, true)) {
            sosOptions = (
                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor="actionItem" className="text-right middle">I Need Help Section</label>
                    </div>
                    <div className={"large-9 small-9 cell"}>
                        <div className="input-group">
                            <input
                                className="input-group-field"
                                name="actionItem"
                                type="text"
                                value={CommonUtil.getOrDefault(formData, "sosServiceTitle", "Please select")}
                                disabled
                            />

                            <div className="input-group-button">
                                <button
                                    type="button"
                                    className="button"
                                    onClick={() => supportItemWasRequested(SectionType.ASSISTANT_NEED_HELP, "sosService")}
                                >
                                    Browse
                                </button>
                            </div>
                        </div>
                        <small className="error"></small>
                        {sosServiceWarningElem}
                    </div>
                </div>
            );
        }

        let chatOptions = [];
        if (CommonUtil.getOrDefault(formData, PluginUtil.configKeys.CHAT_SHOW_CHARLEY, true)) {
            chatOptions = (
                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor="actionItem" className="text-right middle">Available Chat Flows</label>
                    </div>
                    <div className={"large-9 small-9 cell"}>
                        <div className="input-group">
                            <input
                                className="input-group-field"
                                name="actionItem"
                                type="text"
                                value={JSON.parse(CommonUtil.getOrDefault(formData, "chatFlows", "[]")).length + " Chat Flows"}
                                disabled
                            />

                            <div className="input-group-button">
                                <button
                                    type="button"
                                    className="button"
                                    onClick={() => chatFlowListWasRequested()}
                                >
                                    Browse
                                </button>
                            </div>
                        </div>
                        <small className="error"></small>
                        {menuServiceWarningElem}
                    </div>
                </div>
            );
        }

        let menuOptions = [];
        if (CommonUtil.getOrDefault(formData, PluginUtil.configKeys.CHAT_SHOW_OVERFLOW, true)) {
            menuOptions = (
                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor="actionItem" className="text-right middle">Menu Support Section</label>
                    </div>
                    <div className={"large-9 small-9 cell"}>
                        <div className="input-group">
                            <input
                                className="input-group-field"
                                name="actionItem"
                                type="text"
                                value={CommonUtil.getOrDefault(formData, "supportSectionTitle", "Please select")}
                                disabled
                            />

                            <div className="input-group-button">
                                <button
                                    type="button"
                                    className="button"
                                    onClick={() => supportItemWasRequested(SectionType.ASSISTANT_SUPPORT, "supportSection")}
                                >
                                    Browse
                                </button>
                            </div>
                        </div>
                        <small className="error"></small>
                        {menuServiceWarningElem}
                    </div>
                </div>
            );
        }

        headerConfigContent = (
            <>
                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor={PluginUtil.configKeys.CHAT_SHOW_SOS} className="text-right middle">Show SOS button</label>
                    </div>
                    <div className="large-4 small-9 cell">
                        <label className="chkbx-container">
                            <input
                                className="chkbx"
                                type="checkbox"
                                name={PluginUtil.configKeys.CHAT_SHOW_SOS}
                                onChange={handleChange}
                                checked={CommonUtil.getOrDefault(formData, PluginUtil.configKeys.CHAT_SHOW_SOS, true)}
                            />

                            <span className="chkmk"></span>
                        </label>
                    </div>
                </div>

                {sosOptions}

                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor={PluginUtil.configKeys.CHAT_SHOW_CHARLEY} className="text-right middle">Show Charley button</label>
                    </div>
                    <div className="large-4 small-9 cell">
                        <label className="chkbx-container">
                            <input
                                className="chkbx"
                                type="checkbox"
                                name={PluginUtil.configKeys.CHAT_SHOW_CHARLEY}
                                onChange={handleChange}
                                checked={CommonUtil.getOrDefault(formData, PluginUtil.configKeys.CHAT_SHOW_CHARLEY, true)}
                            />

                            <span className="chkmk"></span>
                        </label>
                    </div>
                </div>

                {chatOptions}

                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor={PluginUtil.configKeys.CHAT_SHOW_QR_LINK} className="text-right middle">Show App Download link</label>
                    </div>
                    <div className="large-4 small-9 cell">
                        <label className="chkbx-container">
                            <input
                                className="chkbx"
                                type="checkbox"
                                name={PluginUtil.configKeys.CHAT_SHOW_QR_LINK}
                                onChange={handleChange}
                                checked={CommonUtil.getOrDefault(formData, PluginUtil.configKeys.CHAT_SHOW_QR_LINK, true)}
                            />

                            <span className="chkmk"></span>
                        </label>
                    </div>
                </div>

                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor={PluginUtil.configKeys.CHAT_SHOW_OVERFLOW} className="text-right middle">Show Menu button</label>
                    </div>
                    <div className="large-4 small-9 cell">
                        <label className="chkbx-container">
                            <input
                                className="chkbx"
                                type="checkbox"
                                name={PluginUtil.configKeys.CHAT_SHOW_OVERFLOW}
                                onChange={handleChange}
                                checked={CommonUtil.getOrDefault(formData, PluginUtil.configKeys.CHAT_SHOW_OVERFLOW, true)}
                            />

                            <span className="chkmk"></span>
                        </label>
                    </div>
                </div>

                {menuOptions}

            </>
        )
    }

    let assistantGraphicUploadElem = [];
    if (parseInt(CommonUtil.getOrDefault(formData, "widgetShowIcon", 1)) === 1) {
        let widgetGraphicRemoveButton = [];
        if (CommonUtil.getOrDefault(formData, PluginUtil.configKeys.WIDGET_ASSISTANT_IMAGE_URL, null)) {
            widgetGraphicRemoveButton = (
                <button className={"button danger"} onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    const newData = {};
                    newData[PluginUtil.configKeys.WIDGET_ASSISTANT_IMAGE_ID] = undefined;
                    newData[PluginUtil.configKeys.WIDGET_ASSISTANT_IMAGE_URL] = undefined;
                    putFormData(newData);
                }}>
                    Restore to Default
                </button>
            );
        }

        assistantGraphicUploadElem = (
            <>

                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor="imageFile" className="text-right middle">Change Widget Image</label>
                    </div>
                    <div className="large-9 small-9 cell ">
                        <FileInput
                            name="file"
                            onChange={(e) => handleFilePickerChange(e, "widget")}
                            onDelete={() => handleFileDelete("widget")}
                            placeholder="Not selected."
                            accept="image/jpeg,image/jpg,image/png,image/svg,image/svg+xml"
                        />

                        {widgetGraphicRemoveButton}
                    </div>
                </div>

            </>
        )
    }

    let welcomeScreenGraphicUploadElem = [];
    if (parseInt(CommonUtil.getOrDefault(formData, PluginUtil.configKeys.WELCOME_SCREEN_IMAGE_SHOWN, 1)) === 1) {
        let welcomeScreenGraphicRemoveButton = [];
        if (CommonUtil.getOrDefault(formData, PluginUtil.configKeys.WELCOME_SCREEN_ASSISTANT_IMAGE_URL, null)) {
            welcomeScreenGraphicRemoveButton = (
                <button className={"button danger"} onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    const newData = {};
                    newData[PluginUtil.configKeys.WELCOME_SCREEN_ASSISTANT_IMAGE_ID] = undefined;
                    newData[PluginUtil.configKeys.WELCOME_SCREEN_ASSISTANT_IMAGE_URL] = undefined;
                    putFormData(newData);
                }}>
                    Restore to Default
                </button>
            );
        }

        welcomeScreenGraphicUploadElem = (
            <>

                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor="imageFile" className="text-right middle">Change Chatbot Image</label>
                    </div>
                    <div className="large-9 small-9 cell ">
                        <div
                            className={"plugin-form-image-preview"}
                            style={{
                                marginBottom: "10px",
                                backgroundImage: "url(" + (welcomeGraphicData ? welcomeGraphicData : CommonUtil.getOrDefault(formData, PluginUtil.configKeys.WELCOME_SCREEN_ASSISTANT_IMAGE_URL, iconCharley)) + ")"
                            }}
                        />

                        <FileInput
                            name="file"
                            onChange={(e) => handleFilePickerChange(e, "welcome")}
                            onDelete={() => handleFileDelete("welcome")}
                            placeholder="Not selected."
                            accept="image/jpeg,image/jpg,image/png,image/svg,image/svg+xml"
                        />

                        {welcomeScreenGraphicRemoveButton}

                    </div>
                </div>

            </>
        )
    }

    let widgetEmbedPositionElem = [];
    if (parseInt(CommonUtil.getOrDefault(formData, PluginUtil.configKeys.WIDGET_EMBED_TYPE, 1)) === PluginUtil.embedTypes.FLOATING) {
        widgetEmbedPositionElem = (
            <div className="grid-x grid-padding-x">
                <div className="small-3 cell">
                    <label htmlFor="defaultUserRoleID" className="text-right middle">Floating Button Position</label>
                </div>
                <div className={"small-9 cell"}>
                    <ReactSelect
                        name={PluginUtil.configKeys.WIDGET_EMBED_POSITION}
                        className="react-select"
                        onChange={handleChange}
                        value={CommonUtil.getOrDefault(formData, PluginUtil.configKeys.WIDGET_EMBED_POSITION, PluginUtil.embedPositions.TOP_LEFT)}
                        options={[
                            {
                                value: PluginUtil.embedPositions.TOP_LEFT,
                                label: "Top Left"
                            },
                            {
                                value: PluginUtil.embedPositions.TOP_RIGHT,
                                label: "Top Right"
                            },
                            {
                                value: PluginUtil.embedPositions.BOTTOM_RIGHT,
                                label: "Bottom Right"
                            },
                            {
                                value: PluginUtil.embedPositions.BOTTOM_LEFT,
                                label: "Bottom Left"
                            },
                        ]}
                        onBlur={handleBlur}
                    />
                </div>
            </div>
        )
    }

    return (
        <>
            <ModalProgress show={isUploading}/>
            <div className={"modal-head"}>
                <div className={"modal-head"}>
                    <h3>{modalTitle}</h3>
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className={"modal-body"}>
                    {organisationPicker}

                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="name" className="text-right middle ">* Name</label>
                        </div>
                        <div className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "name", null) && "error")}>
                            <input
                                type="text"
                                name="name"
                                onChange={handleChange}
                                value={CommonUtil.getOrDefault(formData, "name", "")}
                                onBlur={handleBlur}
                            />

                            <small className="error">{CommonUtil.getOrDefault(errors, "name", "")}</small>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="actionItem" className="text-right middle">Default Chat Flow</label>
                        </div>
                        <div className={"large-9 small-9 cell"}>
                            <div className="input-group">
                                <input
                                    className="input-group-field"
                                    name="actionItem"
                                    type="text"
                                    value={CommonUtil.getOrDefault(formData, "chatFlowTitle", "Please Select")}
                                    disabled
                                />

                                <div className="input-group-button">
                                    <button
                                        type="button"
                                        className="button"
                                        onClick={() => chatFlowWasRequested()}
                                    >
                                        Browse
                                    </button>
                                </div>
                            </div>

                            {defaultChatFlowWarningElem}

                            <small className="error"></small>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <fieldset className="fieldset">
                                <legend>Privacy &amp; Authentication</legend>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label className="text-right middle">&nbsp;</label>
                                    </div>
                                    <div
                                        className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "organisationID", null) && "error")}>

                                        <p>
                                            <strong>Important Information</strong><br/>
                                            If this Plugin Configuration is intended to be embedded in situations where
                                            there is an expectation of privacy, enabling User Persistence allows the
                                            user to pick up where they last left-off with the Assistant Plugin. This
                                            option should be used carefully.
                                        </p>

                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor={PluginUtil.configKeys.ALLOW_PERSISTENT_STORAGE}
                                               className="text-right middle">Enable User Persistence</label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <label className="chkbx-container">
                                            <input
                                                className="chkbx"
                                                type="checkbox"
                                                name={PluginUtil.configKeys.ALLOW_PERSISTENT_STORAGE}
                                                onChange={handleChange}
                                                checked={CommonUtil.getOrDefault(formData, PluginUtil.configKeys.ALLOW_PERSISTENT_STORAGE, false)}
                                            />

                                            <span className="chkmk"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="defaultUserRoleID" className="text-right middle">&nbsp;</label>
                                    </div>
                                    <div
                                        className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "organisationID", null) && "error")}>

                                        <p>
                                            <strong>Important Information</strong><br/>
                                            When a user cannot be otherwise identified, the Plugin will create a temporary User account using the following details.
                                        </p>

                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="defaultUserRoleID" className="text-right middle">Default User
                                            Role</label>
                                    </div>
                                    <div
                                        className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "organisationID", null) && "error")}>
                                        <ReactSelect
                                            name="defaultUserRoleID"
                                            className="react-select"
                                            onChange={handleChange}
                                            value={CommonUtil.getOrDefault(formData, "defaultUserRoleID", null)}
                                            options={userRoleOptions}
                                            onBlur={handleBlur}
                                        />
                                        <small className={"field-info"}>The default User Role assumed by the Plugin
                                            before the user provides their own User Role.</small>

                                        <small
                                            className="error">{CommonUtil.getOrDefault(errors, "defualtUserRoleID", "")}</small>
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="defaultLocationID" className="text-right middle">Default
                                            Campus</label>
                                    </div>
                                    <div
                                        className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "defaultLocationID", null) && "error")}>
                                        <ReactSelect
                                            name="defaultLocationID"
                                            className="react-select"
                                            onChange={handleChange}
                                            value={CommonUtil.getOrDefault(formData, "defaultLocationID", null)}
                                            options={locationOptions}
                                            onBlur={handleBlur}
                                        />
                                        <small
                                            className="error">{CommonUtil.getOrDefault(errors, "defaultLocationID", "")}</small>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <fieldset className="fieldset">
                                <legend>Widget Appearance</legend>

                                <div className="grid-x grid-padding-x">
                                    <div className={"small-12 cell"}>

                                        <div
                                            className={"uwbp-widget-container " + CommonUtil.getOrDefault(formData, "widgetAlignment", "left")}>
                                            <div className={"uwbp-widget"} style={{
                                                borderColor: CommonUtil.getOrDefault(formData, "widgetBorderColour"),
                                                backgroundColor: CommonUtil.getOrDefault(formData, "widgetBackgroundColour")
                                            }}>
                                                <div className={"uwbp-widget-content"}>
                                                    <div
                                                        className={"uwbp-widget-content-title"}
                                                        style={{color: CommonUtil.getOrDefault(formData, "widgetTextColour")}}
                                                    >
                                                        {CommonUtil.getStringOrDefault(formData, "widgetTitle", "Wellbeing Assistant")}
                                                    </div>
                                                    {CommonUtil.getOrDefault(formData, "widgetShowIcon", true) ?
                                                        <div className={"uwbp-widget-content-icon"}
                                                             style={{backgroundImage: "url(" + (widgetGraphicData ? widgetGraphicData : CommonUtil.getOrDefault(formData, PluginUtil.configKeys.WIDGET_ASSISTANT_IMAGE_URL, iconCharley)) + ")"}}/>
                                                        :
                                                        []
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x" style={{marginTop: "10px"}}>
                                    <div className="small-3 cell">
                                        <label htmlFor="name" className="text-right middle">Title</label>
                                    </div>
                                    <div className={"small-9 cell"}>
                                        <input
                                            type="text"
                                            name="widgetTitle"
                                            onChange={handleChange}
                                            value={CommonUtil.getOrDefault(formData, "widgetTitle", "")}
                                            placeholder={"Wellbeing Assistant"}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="showCharley" className="text-right middle">Show Charley</label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <label className="chkbx-container">
                                            <input
                                                className="chkbx"
                                                type="checkbox"
                                                name="widgetShowIcon"
                                                onChange={handleChange}
                                                checked={CommonUtil.getOrDefault(formData, "widgetShowIcon", true)}
                                            />

                                            <span className="chkmk"></span>
                                        </label>
                                    </div>
                                </div>

                                {assistantGraphicUploadElem}

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="action" className="text-right middle">Border Colour</label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <ColourPicker
                                            shown={colourPickerShown === "widgetBorderColour"}
                                            name={"widgetBorderColour"}
                                            value={CommonUtil.getOrDefault(formData, "widgetBorderColour", "#000")}
                                            callback={colourPickerDidCallback}
                                        />
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="action" className="text-right middle">Background Colour</label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <ColourPicker
                                            shown={colourPickerShown === "widgetBackgroundColour"}
                                            name={"widgetBackgroundColour"}
                                            value={CommonUtil.getOrDefault(formData, "widgetBackgroundColour", "#FFF")}
                                            callback={colourPickerDidCallback}
                                        />
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="action" className="text-right middle">Text Colour</label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <ColourPicker
                                            shown={colourPickerShown === "widgetTextColour"}
                                            name={"widgetTextColour"}
                                            value={CommonUtil.getOrDefault(formData, "widgetTextColour", "#000")}
                                            callback={colourPickerDidCallback}
                                        />
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="widgetAlignment" className="text-right middle">Widget
                                            Alignment</label>
                                    </div>
                                    <div className="large-9 small-9 cell">
                                        <ReactSelect
                                            name="widgetAlignment"
                                            onChange={handleChange}
                                            value={CommonUtil.getOrDefault(formData, "widgetAlignment", "left")}
                                            options={[
                                                {value: "left", label: "Left"},
                                                {value: "center", label: "Centre"},
                                                {value: "right", label: "Right"}
                                            ]}
                                            isMulti={false}
                                            placeholder="None"
                                            className="react-select"
                                        />
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor={PluginUtil.configKeys.WIDGET_SHOW_EMOTIONS}
                                               className="text-right middle">Show Emotion Selection</label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <label className="chkbx-container">
                                            <input
                                                className="chkbx"
                                                type="checkbox"
                                                name={PluginUtil.configKeys.WIDGET_SHOW_EMOTIONS}
                                                onChange={handleChange}
                                                checked={CommonUtil.getOrDefault(formData, PluginUtil.configKeys.WIDGET_SHOW_EMOTIONS, true)}
                                            />

                                            <span className="chkmk"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor={PluginUtil.configKeys.WIDGET_EMBED_TYPE} className="text-right middle">Display as Floating Widget</label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <label className="chkbx-container">
                                            <input
                                                className="chkbx"
                                                type="checkbox"
                                                name={PluginUtil.configKeys.WIDGET_EMBED_TYPE}
                                                onChange={handleChange}
                                                checked={parseInt(CommonUtil.getOrDefault(formData, PluginUtil.configKeys.WIDGET_EMBED_TYPE, PluginUtil.embedTypes.STATIC)) === PluginUtil.embedTypes.FLOATING}
                                            />

                                            <span className="chkmk"></span>
                                        </label>
                                    </div>
                                </div>

                                {widgetEmbedPositionElem}

                            </fieldset>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <fieldset className="fieldset">
                                <legend>Content Appearance</legend>

                                <div className="grid-x grid-padding-x" style={{marginBottom: "10px"}}>
                                    <div className={"small-12 cell"}>

                                        <div className={"plugin-header-preview"}>
                                            <div
                                                className={"plugin-header " + CommonUtil.getOrDefault(formData, "headerTheme", "light")}
                                                style={{
                                                    backgroundColor: CommonUtil.getOrDefault(formData, "headerBackgroundColour")
                                                }}
                                            >
                                                <div className={"header-action icon invert"}
                                                     style={{backgroundImage: "url(" + iconBack + ")"}}/>

                                                <div className={"header-title"}>
                                                    {CommonUtil.getStringOrDefault(formData, "headerTitle", "Wellbeing Assistant")}
                                                </div>

                                                <div className={"header-action"}/>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="windowTheme" className="text-right middle">Header Style</label>
                                    </div>
                                    <div className="large-9 small-9 cell">
                                        <ReactSelect
                                            name="headerTheme"
                                            onChange={handleChange}
                                            value={CommonUtil.getOrDefault(formData, "headerTheme", "light")}
                                            options={[
                                                {value: "light", label: "Light"},
                                                {value: "dark", label: "Dark"},
                                            ]}
                                            isMulti={false}
                                            placeholder="None"
                                            className="react-select"
                                        />
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x" style={{marginTop: "10px"}}>
                                    <div className="small-3 cell">
                                        <label htmlFor="headerTitle" className="text-right middle">Header Title</label>
                                    </div>
                                    <div className={"small-9 cell"}>
                                        <input
                                            type="text"
                                            name="headerTitle"
                                            onChange={handleChange}
                                            value={CommonUtil.getOrDefault(formData, "headerTitle", "")}
                                            placeholder={"Wellbeing Assistant"}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="headerBackgroundColour" className="text-right middle">Header
                                            Background</label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <ColourPicker
                                            shown={colourPickerShown === "headerBackgroundColour"}
                                            name={"headerBackgroundColour"}
                                            value={CommonUtil.getOrDefault(formData, "headerBackgroundColour", "#000")}
                                            callback={colourPickerDidCallback}
                                        />
                                    </div>
                                </div>

                            </fieldset>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <fieldset className="fieldset">
                                <legend>Welcome Screen</legend>

                                <div className={"grid-x grid-padding-x"}>
                                    <div className="small-3 cell">
                                        <label htmlFor={PluginUtil.configKeys.WELCOME_SCREEN_SHOWN}
                                               className="text-right middle">Show Welcome Screen</label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <label className="chkbx-container">
                                            <input
                                                className="chkbx"
                                                type="checkbox"
                                                name={PluginUtil.configKeys.WELCOME_SCREEN_SHOWN}
                                                onChange={handleChange}
                                                checked={CommonUtil.getOrDefault(formData, PluginUtil.configKeys.WELCOME_SCREEN_SHOWN, true)}
                                            />

                                            <span className="chkmk"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className={"grid-x grid-padding-x"}>
                                    <div className="small-3 cell">
                                        <label htmlFor={PluginUtil.configKeys.WELCOME_SCREEN_IMAGE_SHOWN} className="text-right middle">Show Chatbot Image</label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <label className="chkbx-container">
                                            <input
                                                className="chkbx"
                                                type="checkbox"
                                                name={PluginUtil.configKeys.WELCOME_SCREEN_IMAGE_SHOWN}
                                                onChange={handleChange}
                                                checked={CommonUtil.getOrDefault(formData, PluginUtil.configKeys.WELCOME_SCREEN_IMAGE_SHOWN, true)}
                                            />

                                            <span className="chkmk"></span>
                                        </label>
                                    </div>
                                </div>

                                {welcomeScreenGraphicUploadElem}

                                <div className="grid-x grid-padding-x" style={{marginTop: "10px"}}>
                                    <div className="small-3 cell">
                                        <label htmlFor={PluginUtil.configKeys.WELCOME_SCREEN_MESSAGE}
                                               className="text-right middle">Welcome
                                            Message</label>
                                    </div>
                                    <div className={"small-9 cell"}>
                                        <textarea
                                            name={PluginUtil.configKeys.WELCOME_SCREEN_MESSAGE}
                                            onChange={handleChange}
                                            value={CommonUtil.getOrDefault(formData, PluginUtil.configKeys.WELCOME_SCREEN_MESSAGE, "")}
                                            placeholder={"Hi, I'm your Wellbeing Assistant.\nI'm here to answer your student queries and provide support for your wellbeing."}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x" style={{marginTop: "10px"}}>
                                    <div className="small-3 cell">
                                        <label htmlFor={PluginUtil.configKeys.WELCOME_SCREEN_BUTTON_LABEL}
                                               className="text-right middle">Button label</label>
                                    </div>
                                    <div className={"small-9 cell"}>
                                        <input
                                            type={"text"}
                                            name={PluginUtil.configKeys.WELCOME_SCREEN_BUTTON_LABEL}
                                            onChange={handleChange}
                                            value={CommonUtil.getOrDefault(formData, PluginUtil.configKeys.WELCOME_SCREEN_BUTTON_LABEL, "")}
                                            placeholder={"Start chatting!"}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </div>

                            </fieldset>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <fieldset className="fieldset">
                                <legend>Chat Screen</legend>

                                <div className={"grid-x grid-padding-x"} style={{marginBottom: "10px"}}>
                                    <div className={"small-12 cell"}>
                                        <PluginFormNavigationPreview data={formData}/>
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor={PluginUtil.configKeys.CHAT_SHOW_NAVIGATION}
                                               className="text-right middle">Show Chat
                                            Header</label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <label className="chkbx-container">
                                        <input
                                                className="chkbx"
                                                type="checkbox"
                                                name={PluginUtil.configKeys.CHAT_SHOW_NAVIGATION}
                                                onChange={handleChange}
                                                checked={CommonUtil.getOrDefault(formData, PluginUtil.configKeys.CHAT_SHOW_NAVIGATION, true)}
                                            />

                                            <span className="chkmk"></span>
                                        </label>
                                    </div>
                                </div>

                                {headerConfigContent}
                            </fieldset>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <fieldset className="fieldset">
                                <legend>How to integrate</legend>

                                {integrationExplainElem}

                            </fieldset>
                        </div>
                    </div>

                </div>

                <div className="modal-footer">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <div className="btn-wrap">
                                {
                                    <React.Fragment>
                                        <button
                                            className="button secondary"
                                            type="button"
                                            onClick={() => handleCallback("submit")}
                                        >
                                            Cancel
                                        </button>

                                        <button
                                            className="button success"
                                            type="submit"
                                            disabled={!validateForm()}
                                        >
                                            Submit
                                        </button>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <Modal
                classNames={{modal: "modal-medium"}}
                open={modalOpen}
                modalId="PluginModal"
                onClose={pluginModalDidDismiss}
                center
            >
                {modalContent}
            </Modal>
        </>
    )

}

const ColourPicker = (props) => {

    const {shown} = props;
    const {name} = props;
    const {value} = props;
    const {callback} = props;

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    // RENDER

    let pickerElem = [];
    if (shown) {
        pickerElem = (
            <div className="popover" style={{right: 0}}>
                <div className="cover" onClick={() => handleCallback("blur", name)}/>

                <SketchPicker
                    color={value}
                    onChange={(color) => handleCallback("change", {
                        name,
                        color
                    })}/>
            </div>
        );
    }

    return (
        <div className="input-group-button color-picker">
            <div style={{position: "relative"}}>
                <div
                    className="swatch"
                    onFocus={() => handleCallback("focus", name)}
                    onClick={() => handleCallback("focus", name)}
                >
                    <div className="color" style={{backgroundColor: value || "transparent"}}/>
                </div>

                {pickerElem}
            </div>
        </div>
    );

}